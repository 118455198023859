import { render, staticRenderFns } from "./layout.html?vue&type=template&id=783cb502&scoped=true&"
import script from "./layout.js?vue&type=script&lang=js&"
export * from "./layout.js?vue&type=script&lang=js&"
import style0 from "./layout.scss?vue&type=style&index=0&id=783cb502&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "783cb502",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VMain})
